import React, { useCallback, useState } from 'react';
import Proptypes from 'prop-types';
import { Button, Box, Select, InputLabel, FormControl, MenuItem, Typography, TextField } from '@mui/material';
import AutoComplete from 'react-google-autocomplete';
import { createUseStyles } from '../../styles';
import Polling from '../Polling/Polling';
import useGastroStore from '../../stores/gastroStore';

const usePlanningFlowStyles = createUseStyles(() => ({
    activeWrapper: {
        height: '100%',
        marginBottom: '20px',
    },
    meme: {
        backgroundImage: 'url(/images/memes/balaclava.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '150px',
        height: '178px',
        marginTop: '10px',
    },
    meme2: {
        backgroundImage: 'url(/images/memes/alan.gif)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '377px',
        height: '190px',
        marginTop: '10px',
    },
}));

const PlanningFlow = function PlanningFlow({ submitPlan }) {
    const classes = usePlanningFlowStyles();
    const [pollVisible, setPollVisible] = useState(false);
    const [venueChoice, setVenueChoice] = useState('');
    const [optionsOne, setOptionsOne] = useState({ value: '', option: '' });
    const [venueConfirmed, setVenueConfirmed] = useState(false);
    const [submittedDates, setSubmittedDates] = useState([]);
    const [notes, setNotes] = useState('');
    const [menuLink, setMenuLink] = useState('');
    const { SgSubmittedPlan } = useGastroStore();
    const submittedPlan = SgSubmittedPlan;

    const { option } = optionsOne;

    const options1 = [
        {
            value: 'Y',
            option: 'Yes',
        },
        {
            value: 'N',
            option: 'No',
        },
        {
            value: 'M',
            option: 'Maybe, I have a few options',
        },
    ];

    const submitInternalPlan = useCallback(() => {
        submitPlan({
            venueChoice,
            submittedDates,
            notes,
            menuLink,
        });
    }, [submitPlan, venueChoice, submittedDates, notes, menuLink]);

    const handleChangeOptionsOne = (event) => {
        setOptionsOne(event.target.value);
    };

    return (
        <>
            {/* CHECK FOUR - THE LI USER IS THE ACTIVE GASTRO */}
            <Box className={classes.activeWrapper}>
                <Typography variant="body1">
                    {' '}
                    You can build a poll to suggest a venue and / or a date so everyone can see what is being planned
                    and when.
                </Typography>
                <Typography variant="body1">
                    When you submit this page, the other Gastros will be able to see (and vote on) the suggestions you
                    make.
                </Typography>{' '}
                <Typography variant="body1">
                    You can add some notes too if needed to explain more complicated arrangements.
                </Typography>{' '}
                <Typography variant="body1">Get started below:</Typography>
            </Box>
            <Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setPollVisible(true)}
                    disabled={pollVisible || submittedPlan === 'true'}
                    sx={{ mb: 4 }}
                >
                    Start
                </Button>
            </Box>
            <Box className={classes.pollContainer}>
                {pollVisible && (
                    <Box>
                        <Box>
                            <FormControl fullWidth>
                                <InputLabel id="questionOne">Do you know where you are planning to go?</InputLabel>
                                <Select
                                    labelId="questionOne"
                                    id="select-one"
                                    renderValue={() => option}
                                    label="Do you know where you are planning to go?"
                                    onChange={handleChangeOptionsOne}
                                    disabled={venueConfirmed}
                                    displayEmpty
                                >
                                    {options1.map((item) => (
                                        <MenuItem key={item.value} value={item}>
                                            {item.option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        {optionsOne && optionsOne.value === 'Y' && (
                            <Box my={4}>
                                <Typography variant="body1">Ok, cool. Where you thinking of?</Typography>
                                <AutoComplete
                                    data-testid="venue-input"
                                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                    onPlaceSelected={(place) =>
                                        setVenueChoice(`${place.name} ${place.formatted_address}`)
                                    }
                                    options={{
                                        fields: ['formatted_address', 'name', 'address_components'],
                                        types: ['establishment'],
                                        componentRestrictions: { country: 'uk' },
                                    }}
                                    disabled={venueConfirmed}
                                    style={{
                                        width: '100%',
                                        padding: 20,
                                        borderRadius: 12,
                                        border: '1px solid #ccc',
                                        fontSize: 16,
                                    }}
                                />
                            </Box>
                        )}
                        {optionsOne && optionsOne.value === 'N' && (
                            <Box>
                                <Typography variant="body1">Tough break loser.</Typography>
                                <Box className={classes.meme} />
                            </Box>
                        )}
                        {optionsOne && optionsOne.value === 'M' && (
                            <Box>
                                <Box className={classes.meme2} />
                            </Box>
                        )}
                        {venueChoice && venueChoice.length > 2 && (
                            <Button
                                variant="contained"
                                size="large"
                                color="primary"
                                onClick={() => setVenueConfirmed(true)}
                                disabled={venueChoice.length < 2 || venueConfirmed}
                            >
                                Confirm
                            </Button>
                        )}
                        {venueConfirmed && (
                            <>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    onClick={() => setVenueConfirmed(false)}
                                    sx={{ ml: 6 }}
                                >
                                    Edit
                                </Button>
                                <Typography variant="body1" sx={{ marginTop: '12px' }}>
                                    NICE! Lets throw a few dates about. (Max. 8)
                                </Typography>
                                <Polling onChange={(res) => setSubmittedDates(res)} />
                            </>
                        )}
                        {submittedDates && submittedDates.length > 0 && (
                            <>
                                <Box>
                                    <TextField
                                        label="Add a link here to the menu"
                                        name="select-four"
                                        fullWidth
                                        onChange={(e) => setMenuLink(e.target.value.replace(/[^\w\s]/gi, ''))}
                                        placeholder="Any additional info you might want to add..."
                                        sx={{ mt: 4, mb: 4 }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label="Any Notes to add before you finish?"
                                        name="select-three"
                                        multiline
                                        fullWidth
                                        onChange={(e) => setNotes(e.target.value.replace(/[^\w\s]/gi, ''))}
                                        placeholder="Any additional info you might want to add..."
                                        sx={{ mt: 4, mb: 4 }}
                                    />
                                </Box>
                                <Box>
                                    <Button onClick={() => submitInternalPlan()} size="large" variant="contained">
                                        Submit
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                )}
            </Box>
        </>
    );
};

PlanningFlow.defaultProps = {
    submitPlan: () => {},
};

PlanningFlow.propTypes = {
    submitPlan: Proptypes.func,
};

export default PlanningFlow;
