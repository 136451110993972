const members = [
    {
        memberId: 'GM000000',
        shortName: 'All',
        fullName: 'All',
        profileBadge: '/images/moon.webp',
        mealCount: 1,
        meals: [
            {
                locationId: 'GL001',
            },
        ],
    },
    {
        memberId: 'GM758108',
        shortName: 'Simon',
        fullName: 'Simon Turner',
        profileBadge: '/images/members/si.jpg',
        mealCount: 5,
        meals: [
            { locationId: 'GL002' },
            { locationId: 'GL007' },
            { locationId: 'GL015' },
            { locationId: 'GL025' },
            { locationId: 'GL034' },
        ],
        profile: {
            nickname: 'Sturner',
            strength: 72,
            portionSize: 80,
            taste: 65,
            planning: 91,
            banter: 83,
            flair: 39,
            specialMove: 'Over-ordering',
            overallRating: 72,
        },
    },
    {
        memberId: 'GM991568',
        shortName: 'Brown',
        fullName: 'Lee Brown',
        profileBadge: '/images/members/brown.jpg',
        mealCount: 4,
        meals: [{ locationId: 'GL008' }, { locationId: 'GL017' }, { locationId: 'GL026' }, { locationId: 'GL035' }],
        profile: {
            nickname: 'Brownie / Brown town',
            strength: 81,
            portionSize: 85,
            taste: 79,
            planning: 90,
            banter: 96,
            flair: 70,
            specialMove: 'Bants',
            overallRating: 72,
        },
    },
    {
        memberId: 'GM125488',
        shortName: 'Bryman',
        fullName: 'Lee Bryan',
        profileBadge: '/images/members/bryman.jpg',
        mealCount: 5,
        meals: [
            { locationId: 'GL003' },
            { locationId: 'GL009' },
            { locationId: 'GL018' },
            { locationId: 'GL027' },
            { locationId: 'GL036' },
        ],
        profile: {
            nickname: 'Bryman / Bry-child',
            strength: 40,
            portionSize: 87,
            taste: 66,
            planning: 85,
            banter: 59,
            flair: 72,
            specialMove: 'Smoking, Collecting Expensive Toys',
            overallRating: 72,
        },
    },
    {
        memberId: 'GM649712',
        shortName: 'Badman',
        fullName: 'Lee Cadman',
        profileBadge: '/images/members/lee.jpg',
        mealCount: 3,
        meals: [{ locationId: 'GL010' }, { locationId: 'GL019' }, { locationId: 'GL028' }],
        profile: {
            nickname: 'Badman',
            strength: 75,
            portionSize: 64,
            taste: 90,
            planning: 82,
            banter: 69,
            flair: 62,
            specialMove: 'Stylish Garb',
            overallRating: 72,
        },
    },
    {
        memberId: 'GM453007',
        shortName: 'Andy',
        fullName: 'Andy Soar',
        profileBadge: '/images/members/andy.jpg',
        cardImage: '/images/members/andycard.png',
        mealCount: 4,
        meals: [{ locationId: 'GL006' }, { locationId: 'GL013' }, { locationId: 'GL023' }, { locationId: 'GL031' }],
        profile: {
            nickname: 'Mr Soar',
            strength: 97,
            portionSize: 90,
            taste: 47,
            planning: 63,
            banter: 75,
            flair: 22,
            specialMove: 'Never seeming to be drunk',
            overallRating: 72,
        },
    },
    {
        memberId: 'GM662233',
        shortName: 'Tom',
        fullName: 'Tom Harris',
        profileBadge: '/images/members/tom.jpg',
        mealCount: 3,
        meals: [{ locationId: 'GL004' }, { locationId: 'GL020' }, { locationId: 'GL029' }],
        profile: {
            nickname: 'Thatch / Lushious / Tommy Boy',
            strength: 79,
            portionSize: 100,
            taste: 58,
            planning: 66,
            banter: 51,
            flair: 89,
            specialMove: 'Hair Strength',
            overallRating: 72,
        },
    },
    {
        memberId: 'GM413670',
        shortName: 'Tarps',
        fullName: 'Richard Tarplee',
        profileBadge: '/images/members/tarps.jpg',
        mealCount: 3,
        meals: [{ locationId: 'GL014' }, { locationId: 'GL024' }, { locationId: 'GL033' }],
        profile: {
            nickname: 'Tarps',
            strength: 35,
            portionSize: 79,
            taste: 70,
            planning: 86,
            banter: 23,
            flair: 37,
            specialMove: 'Volume of work talk',
            overallRating: 72,
        },
    },
    {
        memberId: 'GM245547',
        shortName: 'Garford',
        fullName: 'Garford Moore',
        profileBadge: '/images/members/garf.jpg',
        mealCount: 4,
        meals: [{ locationId: 'GL005' }, { locationId: 'GL012' }, { locationId: 'GL021' }, { locationId: 'GL030' }],
        profile: {
            nickname: 'The Taxman',
            strength: 56,
            portionSize: 74,
            taste: 82,
            planning: 52,
            banter: 80,
            flair: 59,
            specialMove: 'Pint Speed',
            overallRating: 72,
        },
    },
    {
        memberId: 'GM007007',
        shortName: 'Pete',
        fullName: 'Pete Ashford',
        profileBadge: '/images/members/pete.jpg',
        mealCount: 1,
        meals: [{ locationId: 'GL016' }],
        profile: {
            nickname: 'Yam / Diddums / 🗿',
            strength: 38,
            portionSize: 76,
            taste: 77,
            planning: 14,
            banter: 89,
            flair: 64,
            specialMove: 'Startling Knowledge of Tanks',
            overallRating: 72,
        },
    },
    {
        memberId: 'GM774541',
        shortName: 'Patrick',
        fullName: 'Patrick Keighery',
        profileBadge: '/images/members/pat.jpg',
        mealCount: 3,
        meals: [{ locationId: 'GL011' }, { locationId: 'GL022' }, { locationId: 'GL032' }],
        profile: {
            nickname: 'Paddy / Pajo / Bastard',
            strength: 84,
            portionSize: 95,
            taste: 72,
            planning: 81,
            banter: 98,
            flair: 57,
            specialMove: 'Not having a real job',
            overallRating: 72,
        },
    },
    {
        memberId: 'GM442876',
        shortName: 'Chris',
        fullName: 'Chris Wyke',
        profileBadge: '/images/members/chris.png',
        mealCount: 0,
        meals: [],
        profile: {
            nickname: 'Wykey / The Welsh one',
            strength: 75,
            portionSize: 80,
            taste: 12,
            planning: 50,
            banter: 88,
            flair: 32,
            specialMove: 'Solid in defence',
            overallRating: 56,
        },
    },
];

export default members;

export const smallList = [
    { memberId: 'GM774541', shortName: 'Patrick' },
    { memberId: 'GM007007', shortName: 'Pete' },
    { memberId: 'GM245547', shortName: 'Garford' },
    { memberId: 'GM413670', shortName: 'Tarps' },
    { memberId: 'GM662233', shortName: 'Tom' },
    { memberId: 'GM453007', shortName: 'Andy' },
    { memberId: 'GM649712', shortName: 'Badman' },
    { memberId: 'GM125488', shortName: 'Bryman' },
    { memberId: 'GM991568', shortName: 'Brown' },
    { memberId: 'GM758108', shortName: 'Simon' },
    { memberId: 'GM442876', shortName: 'Chris' },
];
