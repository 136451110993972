export const light = {
    mode: 'light',
    primary: {
        main: '#ffbf46',
        light: '#ffbf46',
        dark: '#ffbf46',
        contrastText: '#000',
    },
    secondary: {
        light: '#F92A82',
        main: '#F92A82',
        dark: '#F92A82',
        contrastText: '#000',
    },
    text: {
        primary: '#000',
        //   secondary: "#111",
    },
    info: {
        light: '#428EEC',
        main: '#428EEC',
        dark: '#428EEC',
        contrastText: '#ffffff',
    },
    error: {
        main: '#FF7456',
        contrastText: '#ffffff',
    },
    background: {
        default: '#ffbf46',
        paper: '#ffbf46',
    },
};
export default light;
