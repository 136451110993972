import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Avatar,
    Button,
    Tooltip,
    MenuItem,
    ThemeProvider,
    CircularProgress,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useHistory } from 'react-router-dom';
import { logout } from '../services/firebase';
import { getBrand } from '../styles';
import useSettingsStore from '../stores/settingsStore';

function NavBar({ userData, loading }) {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const { risk } = useSettingsStore();

    // const pages = riskUser ? ['Home', 'Locations', 'Members', 'Planning', 'Risk'] : ['Home', 'Locations', 'Members', 'Planning'];
    const pagesArray = useMemo(() => ['Home', 'Locations', 'Members', 'Stats', 'Planning'], []);
    const settings = ['Settings', 'Logout'];

    const history = useHistory();

    useEffect(() => {
        if (risk) {
            pagesArray.push('Risk');
        }
        // if (chat) {
        //   pagesArray.push("Chat");
        // }
    }, [pagesArray, risk]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logUserOut = () => {
        logout();
        window.location = '/';
    };

    const navigateTo = (page) => {
        if (page === 'Home') {
            //  setGradient(false);
            history.push('/');
        } else {
            if (page === 'Locations' || page === 'Members') {
                //    setGradient(true);
                history.push(`/${page}`);
            } else {
                //   setGradient(false);
                history.push(`/${page}`);
            }
            history.push(`/${page}`);
        }
        handleCloseUserMenu();
    };

    return (
        <ThemeProvider theme={getBrand()}>
            <AppBar position="static">
                <Box
                    sx={{
                        zIndex: 999,
                    }}
                >
                    <Toolbar>
                        <Typography
                            sx={{
                                background: 'url(/images/logo.png)',
                                backgroundSize: '100%',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundColor: 'transparent',
                                width: '108px',
                                height: '112px',
                                display: 'flex',
                            }}
                        />
                        {/* This is the Gastronauts Text */}
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            sx={{
                                display: { xs: 'none', sm: 'flex' },
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                textDecoration: 'none',
                                color: 'white',
                                fontFamily: "'Sarpanch', sans-serif",
                                fontSize: { xs: '18px', sm: '32px', md: '42px' },
                                padding: '20px',
                                top: '25px',
                                lineHeight: '20px',
                                userSelect: 'none',
                            }}
                        >
                            Gastronauts
                        </Typography>

                        <Box display={{ xs: 'flex', sm: 'flex', md: 'none' }} flexGrow={1} justifyContent="flex-end">
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                sx={{ color: '#ffbf46' }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'flex', sm: 'flex', md: 'none' },
                                }}
                            >
                                {pagesArray.map((page) => (
                                    <MenuItem key={page} onClick={() => navigateTo(page)}>
                                        <Typography variant="body1">{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        {/* THIS IS THE TEXT MENU */}
                        <Box
                            sx={{
                                flexGrow: { xs: 1 },
                                display: { xs: 'none', md: 'flex' },
                                justifyContent: 'flex-end',
                            }}
                        >
                            {pagesArray.map((page) => (
                                <Button
                                    data-testid={`nav-${page}`}
                                    key={page}
                                    onClick={() => navigateTo(page)}
                                    sx={{
                                        my: 2,
                                        color: 'white',
                                        display: 'block',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        '&:hover': {
                                            color: '#F92A82',
                                        },
                                    }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 2 }}>
                                        {loading && userData.LiUserName === '' ? (
                                            <CircularProgress />
                                        ) : (
                                            <Avatar alt={userData.LiUserName} src={userData.LiProfileBadge} />
                                        )}
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '60px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem
                                            key={setting}
                                            onClick={() => {
                                                if (setting !== 'Logout') {
                                                    navigateTo(setting);
                                                } else {
                                                    logUserOut();
                                                }
                                            }}
                                        >
                                            <Typography textAlign="center">{setting}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                            {/* )} */}
                        </Box>
                    </Toolbar>
                    {/* {
          gradient &&
          <Box sx={{
          position:"absolute",
          zIndex:2,
          right:0,
          left:0,
          height:"80px",
          background: "-webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,1)), color-stop(80%,rgba(255,255,255,0)))",
        }} />
        } */}
                </Box>
            </AppBar>
        </ThemeProvider>
    );
}
export default NavBar;

NavBar.defaultProps = {
    userData: {
        LiUserName: '',
        LiProfileBadge: '',
    },
};
NavBar.propTypes = {
    userData: PropTypes.shape({
        LiUserName: PropTypes.string,
        LiProfileBadge: PropTypes.string,
    }),
};
