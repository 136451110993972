import React, { useEffect, useState } from 'react';
import { Box, Paper, Grid, Button, TextField, CircularProgress, Typography } from '@mui/material';
import Page from '../layouts/Page';
import useGastroStore from '../stores/gastroStore';
import { addChatMessage, fetchChats, deleteChatMessage } from '../services/firebase';
import ChatMessage from '../components/ChatMessage';

const Chat = function Chat() {
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { LiProfileBadge, LiUserName, LiUid } = useGastroStore();
    const [msgs, setMsgs] = useState([]);
    const handleSendMessage = async () => {
        if (message !== '') {
            const newMessageObj = {
                body: message,
                username: LiUserName,
                userimage: LiProfileBadge,
                id: LiUid,
                chatId: LiUid + new Date().toISOString(),
                timestamp: new Date().toISOString(),
            };

            setLoading(true);
            addChatMessage(newMessageObj).then(async () => {
                setMsgs([...msgs, newMessageObj]);
                setMessage('');
                setLoading(false);
            });
        }
    };

    const handleDeleteMessage = async (chatId) => {
        setLoading(true);
        deleteChatMessage(chatId).then(() => {
            const newMessages = msgs.filter((msg) => msg.chatId !== chatId);
            setMsgs(newMessages);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (msgs.length === 0) {
            setLoading(true);
            fetchChats()
                .then((data) => {
                    data.sort((a, b) => {
                        const messageA = a.timestamp;
                        const messageB = b.timestamp;
                        if (messageA < messageB) {
                            return -1;
                        }
                        if (messageA > messageB) {
                            return 1;
                        }
                        return 0;
                    });
                    setMsgs(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [msgs]);

    return (
        <Page>
            <Box backgroundColor="rgba(255,255,255,0.5)" m={{ md: 4 }} p={{ md: 4 }}>
                <Box maxWidth="1446px" display="flex" flexWrap="wrap">
                    <Paper elevation={3} style={{ padding: 16, width: '100%' }}>
                        <Grid container direction="column" spacing={1} display="flex">
                            <Typography variant="h6" color="#000">
                                Use this space for messaging the other Gastronauts
                            </Typography>
                            {msgs.map((item) => (
                                <ChatMessage key={item.chatId} item={item} handleDeleteMessage={handleDeleteMessage} />
                            ))}
                        </Grid>
                        <TextField
                            fullWidth
                            placeholder="Type your message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSendMessage();
                                }
                            }}
                            style={{ marginTop: 8 }}
                        />
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleSendMessage}
                                style={{ marginTop: 8 }}
                            >
                                Send
                            </Button>
                        )}
                    </Paper>
                </Box>
            </Box>
        </Page>
    );
};

export default Chat;
