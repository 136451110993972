import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import Page from '../layouts/Page';
import useLocationStore from '../stores/locationStore';

const Locations = function Locations() {
    const { locations } = useLocationStore();
    const venues = locations;

    const sortedVenues = useMemo(() => {
        venues.sort((a, b) => {
            const locationA = a.locationId;
            const locationB = b.locationId;
            if (locationA < locationB) {
                return 1;
            }
            if (locationA > locationB) {
                return -1;
            }
            return 0;
        });
        return venues;
    }, [venues]);

    // check if the browser is Firefox
    const isFirefox = typeof InstallTrigger !== 'undefined';

    return (
        <Page>
            <Box
                padding={{ xs: 2, sm: 4 }}
                margin={{ xs: 0, sm: 4 }}
                sx={{
                    backgroundImage: 'url(/images/backgrounds/bg-two.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Box
                    maxWidth="1446px"
                    display="flex"
                    flexWrap={{ xs: 'nowrap', sm: 'wrap' }}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'center', sm: 'flex-start' }}
                    backgroundColor="rgba(255,255,255,0.75)"
                    justifyContent="space-between"
                >
                    {sortedVenues.map((venue) => {
                        const { isTransparent, locationId, logo, name } = venue;
                        return (
                            <Box
                                flexDirection="row-reverse"
                                display="flex"
                                justifyContent="space-between"
                                padding={{ xs: 1, sm: isFirefox ? 1 : 4 }}
                                key={locationId}
                            >
                                <Link to={`/venue/${locationId}`}>
                                    <Box
                                        maxWidth={isFirefox && '380px'}
                                        height="100px"
                                        display="flex"
                                        padding="4px"
                                        sx={{
                                            '&:hover': {
                                                transition: !isFirefox && 'transform ease-in-out .3s',
                                                transform: !isFirefox && 'translateY(-12px)',
                                                opacity: isFirefox && '0.5',
                                            },
                                        }}
                                        title={name}
                                    >
                                        <Box
                                            component="img"
                                            alt="Venue logo"
                                            src={logo}
                                            sx={{
                                                height: '100%',
                                                backgroundColor: isTransparent && '#000',
                                                padding: isTransparent && '5px',
                                            }}
                                        />
                                    </Box>
                                </Link>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Page>
    );
};

export default Locations;
