// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from 'firebase/auth';

import {
    getFirestore,
    collection,
    addDoc,
    deleteDoc,
    setDoc,
    doc,
    serverTimestamp,
    updateDoc,
    query,
    where,
    onSnapshot,
    orderBy,
} from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Config
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    experimentalForceLongPolling: true,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// connectAuthEmulator(auth, "http://localhost:3000");
// to start run :  firebase emulators:start --only auth
const db = getFirestore(app);
const userCollection = collection(db, 'users');
export const chatsCollection = collection(db, 'chats');

// // Gets the logged in user data
//  async function getUserData() {
//    const userRef = doc(db, "users", `${auth.currentUser.uid}`);

//    // Get a document, forcing the SDK to fetch from the offline cache.
//    try {
//      const document = await getDocFromCache(userRef);
//      // Document was found in the cache. If no cached document exists,
//      // an error will be returned to the 'catch' block below.
//         console.log("document.data()", document.data());
//     // return document.data();
//    } catch (e) {
//      console.log("Error getting cached document:", e);
//    }
//    return null;
//  };

async function fetchGastro(email) {
    let runQuery = null;
    if (typeof email === 'undefined' || email === null) {
        runQuery = query(userCollection, where('selected', '==', true));
    } else {
        runQuery = query(userCollection, where('email', '==', email));
    }
    return new Promise((resolve, reject) => {
        onSnapshot(
            runQuery,
            (snapshot) => {
                const gastroData = [];
                snapshot.forEach((item) => {
                    const data = item.data();
                    gastroData.push(data);
                });
                resolve(gastroData);
            },
            (error) => {
                reject(error);
            },
        );
    });
}

async function fetchAllGastros() {
    const runQuery = query(userCollection);
    return new Promise((resolve, reject) => {
        onSnapshot(
            runQuery,
            (snapshot) => {
                const userData = [];
                snapshot.forEach((item) => {
                    const data = item.data();
                    userData.push(data);
                });
                resolve(userData);
            },
            (error) => {
                reject(error);
            },
        );
    });
}

async function fetchVenues() {
    const currentVenueQuery = query(collection(db, 'venues'));
    return new Promise((resolve, reject) => {
        onSnapshot(
            currentVenueQuery,
            (snapshot) => {
                const venuesData = [];
                snapshot.forEach((item) => {
                    const data = item.data();
                    venuesData.push(data);
                });
                resolve(venuesData);
            },
            (error) => {
                reject(error);
            },
        );
    });
}

async function fetchEvents() {
    const currentEventQuery = query(
        collection(db, 'events'),
        where('submitted', '==', true),
        //    where("email", "==", email),
        orderBy('timestamp', 'desc'),
    );
    return new Promise((resolve, reject) => {
        onSnapshot(
            currentEventQuery,
            (snapshot) => {
                const eventsData = [];
                snapshot.forEach((item) => {
                    const data = item.data();
                    eventsData.push(data);
                });
                resolve(eventsData);
            },
            (error) => {
                reject(error);
            },
        );
    });
}

async function fetchChats() {
    const currentChatsQuery = query(collection(db, 'chats'));
    return new Promise((resolve, reject) => {
        onSnapshot(
            currentChatsQuery,
            (snapshot) => {
                const chatsData = [];
                snapshot.forEach((item) => {
                    const data = item.data();
                    chatsData.push(data);
                });
                resolve(chatsData);
            },
            (error) => {
                reject(error);
            },
        );
    });
}

// Updates a user record
async function updateUser(user, selected) {
    const userRef = doc(db, 'users', `${user}`);

    // Updates entry to the Firebase database.
    try {
        await updateDoc(userRef, {
            selected,
            submittedPlan: false,
        });
    } catch (error) {
        console.error('Error writing new message to User Record', error);
    }
}

async function selectGastro(user, selected) {
    const userRef = doc(db, 'users', `${user}`);

    // Updates entry to the Firebase database.
    try {
        await updateDoc(userRef, {
            selected,
            submittedPlan: false,
        });
    } catch (error) {
        console.error('Error writing new message to User Record', error);
    }
}

// Adds a venue record
async function addVenue(fields) {
    // Adds entry to the Firebase database.
    try {
        await setDoc(doc(db, 'venues', fields.locationId), {
            cuisine: fields.cuisine,
            description: fields.description,
            isTransparent: fields.isTransparent,
            name: fields.name,
            logo: fields.logo,
            mapLink: fields.mapLink,
            memberId: fields.memberId,
            locationId: fields.locationId,
            hero: fields.hero,
            date: fields.date,
            attendees: fields.attendees,
            menuLink: fields.menuLink,
        });
    } catch (error) {
        console.error('Error writing new message to Venue Record', error);
    }
}

// Adds a chat record
async function addChatMessage(fields) {
    // Adds entry to the Firebase database.
    try {
        await setDoc(doc(db, 'chats', fields.chatId), {
            id: fields.id,
            username: fields.username,
            userimage: fields.userimage,
            body: fields.body,
            timestamp: fields.timestamp,
            chatId: fields.chatId,
        });
    } catch (error) {
        console.error('Error writing new message to Chats Record', error);
    }
}

// Deletes a chat record
async function deleteChatMessage(id) {
    const chatRef = doc(db, 'chats', id);

    // Deletes entry to the Firebase database.
    try {
        await deleteDoc(chatRef);
    } catch (error) {
        console.error('Error deleting chat Record', error);
    }
}

// Updates a venue description
async function updateVenueDesc(desc, locationId) {
    const venueRec = doc(db, 'venues', locationId);

    // Updates entry to the Firebase database.
    try {
        await updateDoc(venueRec, {
            description: desc,
        });
    } catch (error) {
        console.error('Error writing new message to User Record', error);
    }
}

// Updates a venue rating
async function updateVenueRating(rating, locationId) {
    const venueRec = doc(db, 'venues', locationId);

    // Updates entry to the Firebase database.
    try {
        await updateDoc(venueRec, {
            rating,
        });
    } catch (error) {
        console.error('Error writing new message to User Record', error);
    }
}

async function updateSubmittedPlan(user, submitted) {
    const userRef = doc(db, 'users', `${user}`);

    // Updates entry to the Firebase database.
    try {
        await updateDoc(userRef, {
            submittedPlan: submitted,
        });
    } catch (error) {
        console.error('Error writing new message to User Record', error);
    }
}

// Adds a new event record.
async function addEvent(email, details) {
    // Add a new message entry to the Firebase database.
    try {
        await addDoc(collection(getFirestore(), 'events'), {
            email,
            notes: details.notes,
            submitted: true,
            submittedDates: details.submittedDates,
            venueChoice: details.venueChoice,
            timestamp: serverTimestamp(),
        });
    } catch (error) {
        console.error('Error writing new event', error);
    }
}

// Adds a new event record.
async function addEventV2(email, details) {
    // Add a new message entry to the Firebase database.
    try {
        await setDoc(doc(db, 'events', `${details.uid}-${details.venueChoice}`), {
            eventId: `${details.uid}-${details.venueChoice}`,
            email,
            notes: details.notes,
            submitted: true,
            submittedDates: details.submittedDates,
            venueChoice: details.venueChoice,
            timestamp: serverTimestamp(),
            menuLink: details.menuLink,
        });
    } catch (error) {
        console.error('Error writing new event', error);
    }
}

// Adds a new event record.
async function addTest(email, details) {
    // Add a new message entry to the Firebase database.
    try {
        await setDoc(doc(db, 'test', 'Uuu1WZbgnabtbPCsRfMZ'), {
            email,
            submittedDates: details,
        });
    } catch (error) {
        console.error('Error writing new event', error);
    }
}

// Updates an event record with votes from a uid directly against the date
async function updateEvent(eventId, voteString) {
    const eventRef = doc(db, 'events', eventId);
    try {
        await updateDoc(eventRef, {
            votes: voteString,
        });
    } catch (error) {
        console.error('Error writing to Update Event', error);
    }
}

// add a new votes record
// async function buildVotes(date, eventId) {
//   console.log('buildVotes', date, eventId);
//   try {
//     await setDoc(collection(getFirestore(), 'votes'), {
//       date,
//       eventId,
//       timestamp: serverTimestamp(),
//       voters: [],
//     });
//   } catch (error) {
//     console.error('Error writing new event', error);
//   }
// }

// updates a votes record
// async function updateVotes(eventId, voter) {
//   const votesRef = doc(db, 'votes', eventId);
//   try {
//     await updateDoc(votesRef, {
//       voters: voter,
//     });
//   } catch (error) {
//     console.error('Error writing new message to User Record', error);
//   }
// }

// const googleProvider = new GoogleAuthProvider();
// const signInWithGoogle = async () => {
//   try {
//     const res = await signInWithPopup(auth, googleProvider);
//     const { user } = res;
//     const q = query(userCollection, where('uid', '==', user.uid));
//     const docs = await getDocs(q);
//     if (docs.docs.length === 0) {
//       await addDoc(collection(db, 'users'), {
//         uid: user.uid,
//         name: user.displayName,
//         authProvider: 'google',
//         email: user.email,
//       });
//     }
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
    }
};

// const monitorAuthState = async () => {
//   onAuthStateChanged(auth, user => {
//    if(user){
//      // if logged in...
//     return 'LOGGED_IN';
//    }
//    else {
//      return 'LOGGED_OUT';
//    }
//  })
// };

// const registerWithEmailAndPassword = async (name, email, password) => {
//   console.log('register FB called with', name, email, password);
//   try {
//     const res = await createUserWithEmailAndPassword(auth, email, password);
//     const user = res.user;
//     await addDoc(collection(db, "users"), {
//       uid: user.uid,
//       name,
//       authProvider: "local",
//       email,
//     });
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

// Saves a new message to Cloud Firestore.
// async function saveTest(user, messageText) {
//   console.log('triggers the saveTest function with : ', user, messageText);
//   // Add a new message entry to the Firebase database.
//   try {
//     await addDoc(collection(getFirestore(), 'messageCentre'), {
//       uid: user.memberId,
//       name: user.fullName,
//       text: messageText,
//       timestamp: serverTimestamp(),
//     });
//   } catch (error) {
//     console.error('Error writing new message to Firebase Database', error);
//   }
// }

// // Loads chat messages history and listens for upcoming ones.
// function loadMessages(user) {
//   // Create the query to load the last 12 messages and listen for new ones.
//   console.log('user', user);
//   const recentMessagesQuery = query(collection(getFirestore(), 'messageCentre'), where('uid', '==', user));
//
//   // Start listening to the query.
//    onSnapshot(recentMessagesQuery, function(snapshot) {
//     snapshot.docChanges().forEach(function(change) {
//       if (change.type === 'removed') {
//         deleteMessage(change.doc.id);
//       } else {
//         let message = change.doc.data();
//         displayMessage(change.doc.id, message.timestamp, message.name,
//           message.text);
//       }
//     });
//    });
// };

// const streamUserData = (user, snapshot, error) => {
//   const itemsColRef = collection(db, 'users');
//   console.log('user.uid in FB', user.uid);
//   const itemsQuery = query(itemsColRef);
//   return onSnapshot(itemsQuery, snapshot, error);
// };

const registerWithEmailAndPassword = async (name, email, password) => {
    try {
        await createUserWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.log(err.message);
    }
};

// const registerWithEmailAndPassword = async (name, email, password) => {
//   console.log('register FB called with', name, email, password);
//
//
//     const res = await createUserWithEmailAndPassword(auth, email, password).then(async () => {
//     const user = res.user;
//       await addDoc(collection(db, 'users'), {
//         uid: user.uid,
//         name,
//         authProvider: 'local',
//         email,
//       })}).then(()=> console.log('success?')
// );
//
//
//
// };

// const testFunc = async() => {
//   console.log('testFunc fires');
//   await addDoc(collection(db, 'users'), {
//     uid: 'user.uid',
//     name: 'user.displayName',
//     authProvider: 'google',
//     email: 'user.email',
//   }).then(() => {
//     console.log('successfully updated?');
//   });
// };

// const registerWithEmailAndPassword = (name, email, password) => {
//
//   createUserWithEmailAndPassword(auth, email, password).then((cred) => {
//         return db.collection('users').doc(cred.user.uid).set({
//           uid: cred.user.uid,
//           name: cred.user.displayName,
//           authProvider: "local",
//           email: cred.user.email,
//         });
//       })
//     .catch((err) => {
//       console.log(err.message);
//     });
//
// };

// const fetchUserData = async (user)=> {
//   const response=db.collection('users');
//   const data=await response.get();
//   data.docs.forEach(item=>{
//     console.log('set info', setInfo([...blogs,item.data()]));
//   })
//
// };

const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        console.log('Password reset link sent!');
    } catch (err) {
        if (err) {
            console.log(err.message);
        }
        //   console.error(err);
        //   alert(err.message);
    }
};

const logout = () => {
    signOut(auth);
};

export {
    auth,
    db,
    // signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    // streamUserData,
    // saveTest,
    updateUser,
    updateEvent,
    addEvent,
    addEventV2,
    updateSubmittedPlan,
    addVenue,
    updateVenueDesc,
    updateVenueRating,
    // buildVotes, // for when the plan is initially submitted
    // updateVotes, // for when the plan is voted on
    fetchVenues,
    fetchGastro,
    fetchEvents,
    fetchAllGastros,
    addTest,
    addChatMessage,
    fetchChats,
    deleteChatMessage,
    selectGastro,
    // loadMessages
    // monitorAuthState,
};
