import React, { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { CircularProgress, Box } from '@mui/material';
import { Route, HashRouter, Redirect } from 'react-router-dom';
import './styles/App.css';
import PropTypes from 'prop-types';
import Locations from './pages/Locations';
import Main from './pages/Main';
import Members from './pages/Members';
import Login from './pages/Login';
import { auth } from './services/firebase';
import Reset from './components/Reset';
import Settings from './pages/Settings';
import Venue from './pages/Venue';
import Planning from './pages/Planning';
import Stats from './pages/Stats';
import useGastroStore from './stores/gastroStore';
import Chat from './pages/Chat';
import Risk from './pages/Risk';

function App() {
    const [user, loading] = useAuthState(auth);
    const { setUserEmail } = useGastroStore();

    useEffect(() => {
        if (user && !loading) {
            setUserEmail(user.email);
        }
    }, [loading, setUserEmail, user]);

    if (!user && loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                sx={{
                    marginTop: '50vh',
                    position: 'absolute',
                    left: '50%',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <HashRouter>
            <Route exact path="/" render={() => (user ? <Redirect to="/main" /> : <Login />)} />
            <Route path="/main" component={Main} />
            <Route path="/locations" component={Locations} />
            <Route path="/venue/:locationId" component={Venue} />
            <Route path="/members" component={Members} />
            <Route path="/stats" component={Stats} />
            <Route path="/chat" component={Chat} />
            <Route path="/risk" component={Risk} />
            <Route path="/planning" component={Planning} />
            <Route path="/settings" component={Settings} />
            <Route path="/reset" component={Reset} />
        </HashRouter>
    );
}

App.defaultProps = {
    history: undefined,
};

App.propTypes = {
    history: PropTypes.shape({
        location: PropTypes.string,
        push: PropTypes.func,
    }),
};

export default App;
