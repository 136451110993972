import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useHistory } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Button } from '@mui/material';
import { auth } from '../services/firebase';

function Reset() {
    const [email, setEmail] = useState('');
    const [user, loading] = useAuthState(auth);
    const history = useHistory();
    useEffect(() => {
        if (loading) return;
        if (user) history.replace('/main');
    }, [user, loading, history]);
    return (
        <div className="reset">
            <div className="reset__container">
                <input
                    type="text"
                    className="reset__textBox"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                />
                <Button className="reset__btn" onClick={() => sendPasswordResetEmail(email)}>
                    Send password reset email
                </Button>
            </div>
        </div>
    );
}
export default Reset;
