export const getPerson = (id) => {
    const gastros = JSON.parse(sessionStorage.getItem('gastro-storage'));
    const { allGastros } = gastros.state;

    const singleGastro = allGastros.find((person) => person.uid === id);

    if (singleGastro) {
        const { uid, name, profileBadge } = singleGastro;

        return { uid, name, profileBadge };
    }
    return null;
};

export const getNavBarPerson = (email) => {
    switch (email) {
        case 'sturner40@gmail.com':
            return {
                LiProfileBadge: '/images/members/si.jpg',
                LiFullName: 'Simon Turner',
            };
        case 'pete.d.ashford@gmail.com':
            return {
                LiProfileBadge: '/images/members/pete.jpg',
                LiFullName: 'Pete Ashford',
            };
        case 'dragonball.lee@hotmail.com':
            return {
                LiProfileBadge: '/images/members/bryman.jpg',
                LiFullName: 'Lee Bryan',
            };
        case 'garfordmoore@gmail.com':
            return {
                LiProfileBadge: '/images/members/garf.jpg',
                LiFullName: 'Garford Moore',
            };
        case 'corris42@gmail.com':
            return {
                LiProfileBadge: '/images/members/chris.jpg',
                LiFullName: 'Chris Wyke',
            };
        case 'woollylen@gmail.com':
            return {
                LiProfileBadge: '/images/members/andy.jpg',
                LiFullName: 'Andy Soar',
            };
        case 'lac1237@hotmail.co.uk':
            return {
                LiProfileBadge: '/images/members/lee.jpg',
                LiFullName: 'Lee Cadman',
            };
        case 'bilgetank82@gmail.com':
            return {
                LiProfileBadge: '"/images/members/tom.jpg',
                LiFullName: 'Tom Harris',
            };
        case 'pkeighery@gmail.com':
            return {
                LiProfileBadge: '/images/members/pat.jpg',
                LiFullName: 'Patrick Keighery',
            };
        case 'littlecaesar@hotmail.co.uk':
            return {
                LiProfileBadge: '/images/members/brown.jpg',
                LiFullName: 'Lee Brown',
            };
    }
};
