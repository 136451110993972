import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { selectGastro, updateUser } from '../../services/firebase';
import useGastroStore from '../../stores/gastroStore';

const GastroSelecta = function GastroSelecta({ successfullyAdded }) {
    const [loading, setLoading] = useState(false);
    const { SgUid, allGastros } = useGastroStore();
    const [state, setState] = useState({
        selectedGastro: {
            name: '',
            uid: '',
        },
        selected: false,
        prevGastro: SgUid || '',
        success: false,
    });
    const { selectedGastro, selected, prevGastro } = state;

    const sortedGastros = allGastros.sort((a, b) => a.runningOrder - b.runningOrder);

    const updateGastroRec = () => {
        setLoading(true);
        updateUser(prevGastro, false);
        selectGastro(selectedGastro.uid, true).then(() => {
            //  if (response && r) {
            setLoading(false);
            setState((updatedState) => ({
                ...updatedState,
                success: true,
                submittedPlan: false,
            }));
            successfullyAdded(true);
            sessionStorage.setItem('EventData', []);
            //  }
        });
    };
    return (
        <>
            <FormControl fullWidth>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    Select the active gastro selecta
                </Typography>
                <Select
                    data-testid="gastro-select"
                    id="gastro-select"
                    value={selectedGastro}
                    onChange={(option) =>
                        setState((updatedState) => ({
                            ...updatedState,
                            selectedGastro: option.target.value,
                            selected: true,
                        }))
                    }
                >
                    {sortedGastros.map((member) => (
                        <MenuItem key={member.uid} value={member}>
                            {member.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                variant="contained"
                onClick={() => updateGastroRec()}
                disabled={!selected}
                size="large"
                sx={{ mt: 2 }}
                loading={loading.toString()}
                endIcon={loading && <CircularProgress size={20} sx={{ color: '#febf13' }} />}
            >
                Save
            </Button>
        </>
    );
};

GastroSelecta.propTypes = {
    successfullyAdded: PropTypes.func.isRequired,
};

export default GastroSelecta;
