import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControlLabel, Switch } from '@mui/material';
import useSettingsStore from '../stores/settingsStore';
import GastroSelecta from '../components/GastroSelecta/GastroSelecta';
import AddVenueForm from './AddVenueForm';

const GodModeOptions = function GodModeOptions({ successfullyAdded }) {
    const { risk, chat, setRisk, setChat } = useSettingsStore();
    const [chatEnabled, setChatEnabled] = useState(chat);
    const [riskEnabled, setRiskEnabled] = useState(risk);
    const [showAddVenue, setShowAddVenue] = useState(false);

    return (
        <>
            <Box width="100%" display="flex" justifyContent="space-between" mb={2}>
                <FormControlLabel
                    control={
                        <Switch
                            onChange={() => {
                                setChatEnabled(!chatEnabled);
                                setChat(!chatEnabled);
                                // refresh the page
                                window.location.reload();
                            }}
                            checked={chatEnabled}
                        />
                    }
                    label="Enable Chat"
                    mt={4}
                />
                <FormControlLabel
                    control={
                        <Switch
                            onChange={() => {
                                setRiskEnabled(!riskEnabled);
                                setRisk(!riskEnabled);
                                // refresh the page
                                window.location.reload();
                            }}
                            checked={riskEnabled}
                        />
                    }
                    label="Enable Risk"
                    mt={4}
                />
            </Box>
            <GastroSelecta successfullyAdded={(val) => successfullyAdded(val)} />
            <FormControlLabel
                control={<Switch onChange={() => setShowAddVenue(!showAddVenue)} checked={showAddVenue} />}
                label="Add Venue"
                mt={4}
            />
            {showAddVenue && <AddVenueForm successfullyAdded={(val) => successfullyAdded(val)} />}
        </>
    );
};

GodModeOptions.propTypes = {
    successfullyAdded: PropTypes.func.isRequired,
};

export default GodModeOptions;
