import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function PropertyGrid({ 'data-testid': testId, header, children }) {
    return (
        <Box width="100%" mt={2} data-testid={testId}>
            <Typography variant="body1" fontWeight={600} sx={{ mb: 2 }}>
                {header}
            </Typography>
            {children}
        </Box>
    );
}

PropertyGrid.displayName = 'PropertyGrid';

PropertyGrid.defaultProps = {
    'data-testid': 'propertyGrid',
    classes: {
        outerContainer: '',
        gridHeader: '',
    },
};

PropertyGrid.propTypes = {
    classes: PropTypes.shape({
        outerContainer: PropTypes.string,
        gridHeader: PropTypes.string,
    }),
    'data-testid': PropTypes.string,
    header: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
};

export default PropertyGrid;
