import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Box } from '@mui/material';
import { Calendar } from 'react-modern-calendar-datepicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { createUseStyles } from '../../styles';

const usePollStyles = createUseStyles((theme) => ({
    pollContainer: {
        backgroundColor: 'transparent',
        width: '100%',
        borderRadius: '5px',
        padding: '10px',
    },

    pollInner: {
        height: '100%',
        marginRight: '20px',
    },

    pollOuter: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
    },

    datePicker: {
        '&:hover': {
            cursor: 'pointer',
        },
        '& div[class~="-selected"]': {
            background: '#febf13',
        },
    },

    dateBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: '7px',
        borderRadius: '5px',
        padding: '5px',
    },

    dateBoxItem: {
        display: 'flex',
        margin: '12px',
        background: '#febf13',
        padding: '12px',
        borderRadius: '5px',
        justifyContent: 'space-between',
        width: '200px',
    },
}));

const Polling = function Polling({ onChange }) {
    const classes = usePollStyles();

    const newDateObject = new Date();
    const defaultDate = {
        year: newDateObject.getFullYear(),
        month: newDateObject.getMonth() + 1,
        day: newDateObject.getDate(),
    };

    const [selectedDay, setSelectedDay] = useState(defaultDate);
    const [theArray, setTheArray] = useState([]);

    useEffect(() => {
        onChange(theArray);
    }, [onChange, theArray]);

    const addItem = (dateValue) => {
        const { year, day, month } = dateValue;
        const prettyDate = `${day}/${month}/${year}`;
        const foundDupe = theArray.find((element) => prettyDate === element);
        if (theArray.length > 0 && foundDupe) {
            return false;
        }
        setTheArray([...theArray, prettyDate]);
        return true;
    };

    const removeItem = (target) => {
        setTheArray(theArray.filter((_, index) => index !== target));
    };

    const tooManyDates = theArray.length > 7;

    return (
        <Box className={classNames(classes.pollContainer, {})}>
            <Box className={classes.pollOuter}>
                <Box className={classes.pollInner}>
                    <Box className={classes.datePicker}>
                        <Calendar
                            data-testid="calendar"
                            value={selectedDay}
                            onChange={setSelectedDay}
                            minimumDate={defaultDate}
                            shouldHighlightWeekends
                        />
                    </Box>
                </Box>
                <Box className={classes.pollInner}>
                    <Button
                        data-testid="add-button"
                        onClick={() => addItem(selectedDay)}
                        disabled={tooManyDates}
                        size="large"
                        variant="contained"
                    >
                        Add
                    </Button>
                </Box>

                <Box className={classes.pollInner}>
                    <Box id="dateBox" className={classes.dateBox}>
                        {theArray.map((value, index) => (
                            <Box key={value} className={classes.dateBoxItem}>
                                <Box>{value}</Box>
                                <Button size="small" variant="contained" onClick={() => removeItem(index)}>
                                    Remove
                                </Button>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

Polling.defaultProps = {
    onChange: () => {},
};

Polling.propTypes = {
    onChange: PropTypes.func,
};

export default Polling;
