import React from 'react';
import { Box } from '@mui/material';
import Page from '../layouts/Page';
import ProfileCard from '../components/ProfileCard/ProfileCard';
import membersCard from '../services/membersCard';

const Members = function Members() {
    return (
        <Page>
            <Box backgroundColor="rgba(255,255,255,0.5)" m={{ xs: 1, sm: 4 }} p={4}>
                <Box display="flex" flexWrap="wrap">
                    <Box width={1} display="flex" flexDirection="row" justifyContent="space-evenly" flexWrap="wrap">
                        {membersCard.map((member) => (
                            <Box
                                key={member.memberId}
                                width="312px"
                                height="400px"
                                borderRadius="5px"
                                display="flex"
                                transition="transform .2s"
                                p={2}
                            >
                                <ProfileCard
                                    title={member.fullName}
                                    imageLink={member.cardImage}
                                    memberId={member.memberId}
                                />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Page>
    );
};

export default Members;
