import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import { createUseStyles } from 'react-jss';
import { getBrand } from '../styles';
import NavBar from './NavBar';
import { fetchGastro, fetchAllGastros } from '../services/firebase';
import useGastroStore from '../stores/gastroStore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../services/firebase';
import { getNavBarPerson } from '../services/getPerson';

const useTitleBarStyles = createUseStyles(() => ({
    '@keyframes move-background': {
        from: { '-webkit-transform': 'translate3d(0px, 0px, 0px)' },
        to: { '-webkit-transform': 'translate3d(1000px, 0px, 0px)' },
    },
    '@-webkit-keyframes move-background': {
        from: { '-webkit-transform': 'translate3d(0px, 0px, 0px)' },
        to: { '-webkit-transform': 'translate3d(1000px, 0px, 0px)' },
    },

    '@-moz-keyframes move-background': {
        from: { '-webkit-transform': 'translate3d(0px, 0px, 0px)' },
        to: { '-webkit-transform': 'translate3d(1000px, 0px, 0px)' },
    },

    stars: {
        background: 'black url(/images/stars.webp) repeat',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'block',
        zIndex: 0,
        height: '100%',
    },

    twinkling: {
        width: '10000px',
        height: '100%',
        background: 'transparent url(/images/twinkling.webp) repeat',
        backgroundSize: '1000px 1000px',
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 0,
        '-moz-animation': '$move-background 70s linear infinite',
        '-ms-animation': '$move-background 70s linear infinite',
        '-o-animation': '$move-background 70s linear infinite',
        '-webkit-animation': '$move-background 70s linear infinite',
        animation: '$move-background 70s linear infinite',
    },
}));

export default function Page({ children }) {
    useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);

    const [user] = useAuthState(auth);
    const [userLoading, setLoading] = useState(true);
    const [userLoaded, setUserLoaded] = useState({
        LiUserName: '',
        LiProfileBadge: '',
    });

    useEffect(() => {
        if (user.email !== '') {
            const person = getNavBarPerson(user.email);
            setUserLoaded({
                LiUserName: person.LiFullName,
                LiProfileBadge: person.LiProfileBadge,
            });
            setLoading(false);
        }
    }, [user.email]);

    const classes = useTitleBarStyles();

    const {
        setAllGastros,
        setLiUid,
        setLiEmail,
        setLiUserName,
        setLiSelected,
        setLiProfileBadge,
        setSgEmail,
        setSgName,
        setSgProfileBadge,
        setSgSelected,
        setSgUid,
        setSgSubmittedPlan,
    } = useGastroStore();

    useEffect(() => {
        if (userLoaded.LiProfileBadge === '') {
            fetchGastro(user.email)
                .then((data) => {
                    setLiUserName(data[0].name);
                    setLiProfileBadge(data[0].profileBadge);
                    setLiSelected(data[0].selected);
                    setLiUid(data[0].uid);
                    setLiEmail(data[0].email);
                })
                .then(() => {
                    fetchGastro()
                        .then((data) => {
                            const { selected, uid, submittedPlan } = data[0];
                            setSgEmail(data[0].email);
                            setSgName(data[0].name);
                            setSgProfileBadge(data[0].profileBadge);
                            setSgSelected(selected);
                            setSgUid(uid);
                            setSgSubmittedPlan(submittedPlan);
                        })
                        .catch((error) => {
                            console.error(new Error(error));
                        });
                })
                .catch((error) => {
                    console.error(new Error(error));
                });
        }
        fetchAllGastros()
            .then((data) => {
                setAllGastros(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [
        userLoading,
        userLoaded.LiProfileBadge,
        userLoaded.UserEmail,
        setAllGastros,
        setLiEmail,
        setLiProfileBadge,
        setLiSelected,
        setLiUid,
        setLiUserName,
        setSgEmail,
        setSgName,
        setSgProfileBadge,
        setSgSelected,
        setSgSubmittedPlan,
        setSgUid,
        userLoaded.profileBadge,
    ]);

    return (
        <ThemeProvider theme={getBrand()}>
            <CssBaseline />
            <Box display="flex" position="fixed" width={1} height={1}>
                <Box>
                    <Box className={classes.stars} />
                    <Box className={classes.twinkling} />
                </Box>
                <Box display={{ sm: 'flex' }} flexDirection={{ xs: 'column' }} flexGrow={1}>
                    <NavBar userData={userLoaded} loading={userLoading} />
                    <Box
                        width={{ xs: '100vw', sm: '100%' }}
                        display="flex"
                        flexDirection="column"
                        height={{ xs: 'calc(100vh - 200px)', sm: '100%' }}
                        overflow="auto"
                        position="relative"
                        zIndex={0}
                    >
                        <Box width="100%" maxWidth="1200px" m="0 auto" fontSize={14}>
                            {userLoading ? <CircularProgress /> : children}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

Page.propTypes = {
    children: PropTypes.node.isRequired,
};
