import { useMediaQuery } from '@mui/material';
import React from 'react';
import { Chart } from 'react-google-charts';
import useLocationStore from '../../stores/locationStore';

const VisitsByYear = function VisitsByYear() {
    const { locations } = useLocationStore();

    const dates = locations.map((location) => location.date);
    const years = dates.map((date) => date.split(' ').pop());

    const getData = () => {
        // Function to count years and format data for the chart
        const countYears = (data) => {
            const yearsCounts = data.reduce((counts, year) => {
                const newCounts = { ...counts };
                newCounts[year] = (newCounts[year] || 0) + 1;
                return newCounts;
            }, {});

            // Convert counts into an array of arrays for the chart
            const chartData = [['Year', 'Count']];
            Object.keys(yearsCounts).forEach((cuisine) => {
                if (Object.prototype.hasOwnProperty.call(yearsCounts, cuisine)) {
                    chartData.push([cuisine, yearsCounts[cuisine]]);
                }
            });

            return chartData;
        };

        const formattedChartData = countYears(years);
        return formattedChartData;
    };

    const isDesktop = useMediaQuery('(min-width:960px)');

    const options = {
        title: 'Visits by year',
        pieHole: 0.4,
        is3D: true,
    };

    const mobileOptions = {
        title: 'Visits by year',
        legend: 'none',
    };

    return isDesktop ? (
        <Chart chartType="PieChart" data={getData()} width="100%" height="500px" options={options} legendToggle />
    ) : (
        <Chart chartType="BarChart" data={getData()} width="100%" height="400px" options={mobileOptions} legendToggle />
    );
};

export default VisitsByYear;
