// import dotenv from "dotenv";
import React from 'react';
import ReactDOM from 'react-dom';
import './App/styles/index.css';
import App from './App/App';
// mport reportWebVitals from "./reportWebVitals";

// dotenv.config();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eportWebVitals();
