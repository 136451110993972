import { createTheme } from '@mui/material/styles';
import { light } from './palette';

export { default as createUseStyles } from './createUseStyles';

export const getBrand = () =>
    createTheme({
        palette: light,
        typography: {
            title: {
                fontFamily: "'Sarpanch', sans-serif",
            },
            fontFamily: "'Cabin', sans-serif",
            h1: {
                fontFamily: "'Cabin', sans-serif",
            },
            h2: {
                fontFamily: "'Cabin', sans-serif",
            },
            h3: {
                fontFamily: "'Cabin', sans-serif",
            },
            h4: {
                fontFamily: "'Cabin', sans-serif",
            },
            button: {
                textTransform: 'none',
                fontWeight: 'medium',
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: 700,
                        borderRadius: 12,
                        paddingTop: 10,
                        paddingBottom: 10,
                        elevation: 0,
                        boxShadow: 'none !important',
                        color: '#111',
                        '&.Mui-disabled': {
                            backgroundColor: '#ffbf46',
                            opacity: 0.5,
                        },
                        '&.Mui-disabled:hover': {
                            background: '#ffbf46',
                        },
                    },

                    containedSecondary: { color: '#fff' },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        borderRadius: 12,
                        bgcolor: 'background.paper',
                    },
                    input: {
                        paddingLeft: 10,
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: 12,
                    },
                    input: {
                        borderRadius: 12,
                        bgcolor: 'background.paper',
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                        boxShadow: 'none',
                        backgroundColor: '#f6f6f6',
                        border: '0px solid #f6f6f6',
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        borderRadius: 12,
                        backgroundColor: '#fff',
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: '#000',
                    },
                },
            },
        },
    });
export default getBrand;
