import PropTypes from 'prop-types';
import React from 'react';
import { Box, Typography } from '@mui/material';

function Property({ name, value, 'data-testid': testId }) {
    return (
        <Box width="100%" mb={1} display="flex" data-testid={testId}>
            <Typography variant="body2" width="100px" fontSize="14px">{`${name}:`}</Typography>
            <Typography variant="body2" fontWeight={600}>
                {value}
            </Typography>
        </Box>
    );
}
Property.displayName = 'Property';

Property.defaultProps = {
    'data-testid': 'property',
    classes: {
        key: '',
        value: '',
        pairContainer: '',
    },
};
Property.propTypes = {
    classes: PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
        pairContainer: PropTypes.string,
    }),
    'data-testid': PropTypes.string,
    name: PropTypes.node.isRequired,
    value: PropTypes.node.isRequired,
};

export default Property;
