import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Button, TextField, Box, CircularProgress, Typography, ThemeProvider } from '@mui/material';
import { createUseStyles, getBrand } from '../styles';
import { auth, logInWithEmailAndPassword } from '../services/firebase';

const useLoginStyles = createUseStyles(() => ({
    '@keyframes move-background': {
        from: { '-webkit-transform': 'translate3d(0px, 0px, 0px)' },
        to: { '-webkit-transform': 'translate3d(1000px, 0px, 0px)' },
    },
    '@-webkit-keyframes move-background': {
        from: { '-webkit-transform': 'translate3d(0px, 0px, 0px)' },
        to: { '-webkit-transform': 'translate3d(1000px, 0px, 0px)' },
    },

    '@-moz-keyframes move-background': {
        from: { '-webkit-transform': 'translate3d(0px, 0px, 0px)' },
        to: { '-webkit-transform': 'translate3d(1000px, 0px, 0px)' },
    },

    stars: {
        background: 'black url(/images/stars.webp) repeat',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'block',
        zIndex: 0,
    },

    twinkling: {
        width: '10000px',
        height: '100%',
        background: 'transparent url(/images/twinkling.webp) repeat',
        backgroundSize: '1000px 1000px',
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 2,

        '-moz-animation': '$move-background 70s linear infinite',
        '-ms-animation': '$move-background 70s linear infinite',
        '-o-animation': '$move-background 70s linear infinite',
        '-webkit-animation': '$move-background 70s linear infinite',
        animation: '$move-background 70s linear infinite',
    },

    clouds: {
        width: '10000px',
        height: '100%',
        background: 'transparent url(/images/clouds_repeat.webp) repeat',
        backgroundSize: '1000px 1000px',
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 3,

        '-moz-animation': '$move-background 150s linear infinite',
        '-ms-animation': '$move-background 150s linear infinite',
        '-o-animation': '$move-background 150s linear infinite',
        '-webkit-animation': '$move-background 150s linear infinite',
        animation: '$move-background 150s linear infinite',
    },
}));

const Login = function Login() {
    const classes = useLoginStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, loading, error] = useAuthState(auth);
    const navigate = useHistory();

    useEffect(() => {
        if (loading) {
            return <CircularProgress />;
        }
        if (user && !loading && !error) {
            window.location = '/main';
        }
        return null;
    }, [user, loading, navigate, error]);

    return (
        <ThemeProvider theme={getBrand()}>
            <Box
                display="flex"
                flexDirection="column"
                height="100vh"
                padding={2}
                alignItems="center"
                justifyContent="center"
                position="fixed"
                top={0}
                left={0}
                bottom={0}
                right={0}
                zIndex={999}
            >
                <Box className={classes.stars} />
                <Box className={classes.twinkling} />
                <Box className={classes.clouds} />
                <Box
                    sx={{
                        background: 'url(/images/moon3.webp)',
                        backgroundSize: { xs: '180px 180px', sm: '250px 250px' },
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundColor: 'transparent',
                        height: '300px',
                        width: '300px',
                        position: 'absolute',
                        zIndex: 2,
                        right: '365px',
                        top: { xs: '80px', sm: '120px' },
                        left: { xs: '40%', sm: '60%' },
                    }}
                />

                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    zIndex={999}
                    position="absolute"
                    width="100%"
                    height="570px"
                    bottom="180px"
                >
                    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                        <Box component="img" src="/images/logo.png" alt="logo" width="215px" height="112px" />
                        <Typography
                            variant="h2"
                            fontFamily={"'Sarpanch', sans-serif"}
                            fontSize={{ xs: '36px', sm: '60px' }}
                            color="#fff"
                        >
                            Gastronauts
                        </Typography>
                    </Box>
                    <Box
                        component="form"
                        autoComplete="current-password"
                        width="100%"
                        maxWidth="300px"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        position="absolute"
                        zIndex="999"
                        backgroundColor="#fff"
                        borderRadius="12px"
                        p={1}
                        bottom="120px"
                    >
                        <TextField
                            fullWidth
                            required
                            id="standard-required-email"
                            label="Email"
                            variant="standard"
                            autoComplete="current-email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            sx={{
                                mb: 2,
                            }}
                        />
                        <TextField
                            fullWidth
                            required
                            id="standard-required-pw"
                            label="Password"
                            variant="standard"
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            type="password"
                            sx={{
                                mb: 2,
                            }}
                        />
                    </Box>
                    <Box
                        width="100%"
                        maxWidth="300px"
                        bottom="0px"
                        position="absolute"
                        display="flex"
                        flexDirection="column"
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => logInWithEmailAndPassword(email, password)}
                            disabled={email.length < 2 && password.length < 2}
                        >
                            Login
                        </Button>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

Login.propTypes = {};

export default Login;
